// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-herb-js": () => import("./../src/templates/herb.js" /* webpackChunkName: "component---src-templates-herb-js" */),
  "component---src-templates-ailment-js": () => import("./../src/templates/ailment.js" /* webpackChunkName: "component---src-templates-ailment-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-account-email-js": () => import("./../src/pages/account/email.js" /* webpackChunkName: "component---src-pages-account-email-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-account-name-js": () => import("./../src/pages/account/name.js" /* webpackChunkName: "component---src-pages-account-name-js" */),
  "component---src-pages-account-password-js": () => import("./../src/pages/account/password.js" /* webpackChunkName: "component---src-pages-account-password-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-index-js": () => import("./../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-ailments-js": () => import("./../src/pages/ailments.js" /* webpackChunkName: "component---src-pages-ailments-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-create-herb-review-index-js": () => import("./../src/pages/create-herb-review/index.js" /* webpackChunkName: "component---src-pages-create-herb-review-index-js" */),
  "component---src-pages-create-herb-review-thank-you-js": () => import("./../src/pages/create-herb-review/thank-you.js" /* webpackChunkName: "component---src-pages-create-herb-review-thank-you-js" */),
  "component---src-pages-disclaimer-js": () => import("./../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-download-app-js": () => import("./../src/pages/download-app.js" /* webpackChunkName: "component---src-pages-download-app-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-favorites-js": () => import("./../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-herbs-js": () => import("./../src/pages/herbs.js" /* webpackChunkName: "component---src-pages-herbs-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-symptom-checker-ailment-results-js": () => import("./../src/pages/symptom-checker/ailment-results.js" /* webpackChunkName: "component---src-pages-symptom-checker-ailment-results-js" */),
  "component---src-pages-symptom-checker-index-js": () => import("./../src/pages/symptom-checker/index.js" /* webpackChunkName: "component---src-pages-symptom-checker-index-js" */),
  "component---src-pages-symptom-checker-select-symptoms-js": () => import("./../src/pages/symptom-checker/select-symptoms.js" /* webpackChunkName: "component---src-pages-symptom-checker-select-symptoms-js" */),
  "component---src-pages-user-agreement-js": () => import("./../src/pages/user-agreement.js" /* webpackChunkName: "component---src-pages-user-agreement-js" */)
}

